import React from 'react';
import styles from './MegaMenu.module.scss';

export const MegaMenuItem = React.forwardRef(({ onClick, href, ...props }, ref) => {
  return (
    <a href={href} onClick={onClick} ref={ref}>
      <div className={`${styles.category}`}>
        {props.iconUrl && (
          <div className={styles.icon}>
            <img src={props.iconUrl} alt={props.iconAlt} width={40} height={40} />
          </div>
        )}
        <div className={styles.text}>
          <div className={styles.title}>{props.title}</div>
          {props.description && <div className={styles.description}>{props.description}</div>}
        </div>
      </div>
    </a>
  );
});
