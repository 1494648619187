import { render } from 'react-dom';

export const replaceWithReactComponent = (ReactComponent, selector, numberOfParents) => {

  try {
    let element = document.querySelectorAll(selector)[0];
    if (numberOfParents > 0) {
      for (let i = 0; i < numberOfParents; i++) {
        element = element.parentNode;
      }
    }
    render(<ReactComponent />, element);
  } catch (error) {
    console.warn(`Failure to replace selector ${selector}`, { error });
  }
};
