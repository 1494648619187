import './index.scss';
import $ from 'jquery';
import { dynamicallyLoadCompanionCss } from './utils/dynamicallyLoadCompanionCss';
import { replaceWithReactComponent } from './utils/react';
import { getUrlParam } from './utils/params';
import { MegaMenu } from './components/MegaMenu';
//import { FluidConceptsApp } from './components/FluidConceptsApp';
import { QuoteShelf } from './components/QuoteShelf';

const debugMode = getUrlParam('debug');

if (debugMode) {
  console.log('Debug mode activated');
} else {
  dynamicallyLoadCompanionCss();

  const modifyDom = () => {
    // hide the mod module
    replaceWithReactComponent(MegaMenu, '#productCollectionMenu');
    replaceWithReactComponent(QuoteShelf, '#hd-project-container');
    //replaceWithReactComponent(FluidConceptsApp, '.product-story-content');
  };

  const executeWhenItsReady = (testCondition, callbackToExecute) => {
    let interval = null;
    const tryToExecute = () => {
      const result = testCondition();
      console.log(result);
      console.log('DOM is not ready for mod yet');
      if (result) {
        callbackToExecute();
        clearInterval(interval); // Change it via reference
      }
    };

    interval = setInterval(tryToExecute, 100);
  };

  executeWhenItsReady(
    () => {
      return $('.hd-tab').length > 0;
    },
    () => {
      console.log('Dom ready, mod loaded.');
      modifyDom();
    },
  );
}
