import { useState, useEffect } from 'react';
import styles from './QuoteShelf.module.scss';
import { QuoteShelfItem } from './QuoteShelfItem';
import $ from 'jquery';

export const QuoteShelf = () => {
  const [showShelf, setShowShelf] = useState(false);
  const [cart, setCart] = useState({});

  const removeItem = itemId => {
    const idAsString = itemId.toString();
    // remove item to localstorage cart
    // Get JSON of Current Cart
    let localStorageCartTemp = window.localStorage.getItem('store-0001');
    let localStorageCartTempJSON = JSON.parse(localStorageCartTemp);
    // Remove item
    let updatedItems = localStorageCartTempJSON.items.filter(function (obj) {
      return obj.id !== idAsString;
    });

    localStorageCartTempJSON.items = updatedItems;
    // stringify and update localstorage
    window.localStorage.setItem('store-0001', JSON.stringify(localStorageCartTempJSON));

    // update cart in state
    if (localStorageCartTempJSON) {
      setCart(localStorageCartTempJSON);
    }
  };

  const addItemAndUpdateCart = () => {
    // add item to localstorage cart
    // Get JSON of Current Cart
    let localStorageCartTemp = window.localStorage.getItem('store-0001');
    let localStorageCartTempJSON = JSON.parse(localStorageCartTemp);
    // Push new item
    let currentItem = window?.currentItem;
    // Modify item with extras
    currentItem.id = currentItem.id.toString();
    currentItem.quantity = 1;
    currentItem.type = 'item';
    currentItem.note = 'This is my customization';

    // ONLY ADD ITEM IF NOT ALREADY IN CART
    if (localStorageCartTempJSON?.items.some(e => e.id === currentItem.id)) {
      /* vendors contains the element we're looking for */
      alert('This item is already in the cart.');
    } else {
      localStorageCartTempJSON?.items?.push(window?.currentItem);
      // stringify and update localstorage
      window.localStorage.setItem('store-0001', JSON.stringify(localStorageCartTempJSON));

      // update cart in state
      if (localStorageCartTempJSON) {
        setCart(localStorageCartTempJSON);
      }
    }

    // show shelf
    setShowShelf(true);
  };

  useEffect(() => {
    const localStorageCart = window.localStorage.getItem('store-0001');
    if (localStorageCart) {
      setCart(JSON.parse(localStorageCart));
    } else {
      window.localStorage.setItem(
        'store-0001',
        `{"budgetEstimates":[],"items":[],"fields":{"firstname":"","lastname":"","email":"","phone":"","job":"","company":"","city":"","region":""},"recentlyViewed":[],"contentExperiment":{"expId":"none","variant":-1},"sessionStartTime":0,"didGreet":false,"showChat":false}`,
      );
    }

    $('#spModsGetPricingButton').html(
      ` <button class="sp-button sp-button-dark">Get Pricing Now<div class='type-arrow type-arrow-right' alt='right arrow'></div></button>`,
    );

    $('#spModsGetPricingButton button').on('click', () => {
      addItemAndUpdateCart();
    });
  }, []);

  console.log(cart);

  return (
    <>
      <a
        id="hd-project"
        className="hd-tab"
        onClick={() => {
          setShowShelf(true);
        }}>
        {cart?.items?.length}
      </a>
      <div className={`${styles.quoteShelfContainer} ${showShelf ? styles.show : styles.hide}`}>
        <div className={styles.headerBar}>
          <div className={styles.title}>Your cart</div>
          <a href="/your-project/specs">
            <div className={styles.getQuoteButton}>Get quote ></div>
          </a>
        </div>
        <div className={styles.cartTable}>
          {cart?.items?.map(item => (
            <QuoteShelfItem item={item} removeItem={removeItem} />
          ))}
          <a href="/your-project/specs">
            <div className={styles.getQuoteButtonBottom}>Get quote ></div>
          </a>
        </div>
      </div>
      <div
        onClick={() => {
          setShowShelf(false);
        }}
        className={`${styles.backgroundOverlay} ${showShelf ? styles.showOverlay : styles.hideOverlay}`}></div>
    </>
  );
};
