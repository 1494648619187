const loadStylesheet = url => {
  const head = document.getElementsByTagName('HEAD')[0];
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = url;
  head.appendChild(link);
};

const changeUrlExtension = (url, newExtension) => url.substr(0, url.lastIndexOf('.') + 1) + newExtension;

export const dynamicallyLoadCompanionCss = () => {
  const currentScriptUrl = document.currentScript.getAttribute('src');
  const stylesheetUrl = changeUrlExtension(currentScriptUrl, 'css');
  loadStylesheet(stylesheetUrl);
};
