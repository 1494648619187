import { useState } from 'react';
import styles from './QuoteShelfItem.module.scss';

export const QuoteShelfItem = ({ item, removeItem }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      className={styles.itemContainer}>
      {hover ? (
        <>
          <a href={item.url} className={styles.hoverButton}>
            See details
          </a>
          <div
            className={styles.removeButton}
            onClick={() => {
              removeItem(item.id);
            }}>
            Remove
          </div>
        </>
      ) : (
        ''
      )}
      <div className={styles.item}>
        <div className={styles.image}>
          <img src={`https://media.strongproject.com/${item.filename}`} />
        </div>
        <div className={styles.productId}>{item.id}</div>
      </div>
    </div>
  );
};
