import { useState } from 'react';
import styles from './MegaMenu.module.scss';
import { MegaMenuItem } from './MegaMenuItem';
import $ from 'jquery';

export const MegaMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // there are two different renderings of the main menu, so we have to adjust
  let smarty = false;
  if ($('#sp-root').length) {
    smarty = true;
  }

  return (
    <>
      {smarty ? (
        <div className="hd-tab-content">
          <div className={`${styles.mainLink} ${styles.noBorder}`}>
            <a onMouseEnter={() => setMenuOpen(true)} href="/office-furniture-los-angeles">
              <span>
                Product <br />
                Collection
              </span>
            </a>
          </div>
        </div>
      ) : (
        <div className={styles.mainLink}>
          <a onMouseEnter={() => setMenuOpen(true)} href="/office-furniture-los-angeles">
            Product Collection
          </a>
        </div>
      )}

      <div
        onMouseLeave={() => {
          setMenuOpen(false);
        }}
        className={
          menuOpen
            ? `${styles.megaMenu} ${styles.showMenu} ${smarty ? styles.smartyMegaMenu : ''}`
            : `${styles.megaMenu} ${smarty ? styles.smartyMegaMenu : ''}`
        }>
        <div className={styles.categoryMain}>
          <a href="/hybrid-workplace-office-design">
            <MegaMenuItem
              iconUrl="/static/images/categories/icons/dark/hybrid-workplace-office.png"
              iconAlt="StrongProject Modern Office Furniture Logo"
              title="Hybrid Furniture"
              description="Furniture that's purpose-built for hybrid work models"
            />
          </a>
          <a href="/office-furniture-desk">
            <MegaMenuItem
              iconUrl="/static/images/categories/icons/dark/office-furniture-desk.png"
              iconAlt="StrongProject Modern Office Furniture Logo"
              title="Modern Office Desks"
              description="Height-adjustable with the latest designs"
            />
          </a>
          <a href="/modular-office-furniture-workstation-cubicle">
            <MegaMenuItem
              iconUrl="/static/images/categories/icons/dark/modular-office-furniture-workstation-cubicle.png"
              iconAlt="StrongProject Modern Office Furniture Logo"
              title="Modern Cubicles"
              description="Modular workstations with the latest technology"
            />
          </a>
          <a href="/modern-conference-tables">
            <MegaMenuItem
              iconUrl="/static/images/categories/icons/dark/modern-conference-tables.png"
              iconAlt="StrongProject Modern Office Furniture Logo"
              title="Conference Tables"
              description="High-quality conference and boardroom tables"
            />
          </a>
          <a href="/collaboration-furniture">
            <MegaMenuItem
              iconUrl="/static/images/categories/icons/dark/collaboration-furniture.png"
              iconAlt="StrongProject Modern Office Furniture Logo"
              title="Collaborative Furniture"
              description="Facilitate creativity and innovation"
            />
          </a>
          <a href="/acoustic-office-furniture-sound-absorbing">
            <MegaMenuItem
              iconUrl="/static/images/categories/icons/dark/acoustic-office-furniture-sound-absorbing.png"
              iconAlt="StrongProject Modern Office Furniture Logo"
              title="Acoustic Furniture & Phone Booths"
              description="Create islands of intimate social space and privacy"
            />
          </a>
        </div>
        <div className={styles.categorySecondary}>
          <a href="/reception-desk-station-office">
            <MegaMenuItem title="Modern Reception Desks" />
          </a>
          <a href="/office-chair">
            <MegaMenuItem title="Modern Office Chairs" />
          </a>
          <a href="/adjustable-height-desks">
            <MegaMenuItem title="Modern Standing Desks" />
          </a>
          <a href="/training-room-tables">
            <MegaMenuItem title="Training Room Tables & Breakroom" />
          </a>
          <a href="/office-social-distancing-barrier-cubicle">
            <MegaMenuItem title="Social Distancing Furniture" />
          </a>
          <a href="/filing-system-cabinet">
            <MegaMenuItem title="Office Storage Cabinets & Wall Units" />
          </a>
          <a href="/custom-office-furniture">
            <MegaMenuItem title="Custom Office Furniture" />
          </a>
          <a href="/occasional-end-coffee-table">
            <MegaMenuItem title="Occasional & Laptop Tables" />
          </a>
          <br />
          <a href="/office-furniture-los-angeles">
            <MegaMenuItem title="See full collection" />
          </a>
        </div>
        <div className={styles.testimonial}>
          <div className={styles.title}>
            From blueprint to reality, Strong Project is the cutting edge resource for Modern Office Design and
            furnishing Creative Office Spaces.
            <br />
            <br /> See how Strong Project worked with these company leaders to bring their office to life:
          </div>
          <a href="/modern-office-interiors">
            <a>
              <img
                className={styles.image}
                src="/static/images/mods/testimonial.webp"
                alt="Strong Project testimonial video"
              />
            </a>
          </a>
        </div>
      </div>
    </>
  );
};
